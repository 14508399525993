import { defineStore } from "pinia";
import axios from "@/services/axiosService";
import { formatDateFromTimestamp } from "@/utils";
import { toHandlerKey } from "vue";

export const useUserStore = defineStore("user", {
  state: () => ({
    userData: {
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      role: "",
      state: null,
      password: "",
      photo: null,
    },
    error: null,
    isLoading: false,
    users: [],
  }),
  actions: {
    async registerUser(userData) {
      try {
        if (!userData) {
          throw new Error("User data is required");
        } else {
          this.isLoading = true;
          const token = localStorage.getItem("token");
          const formData = new FormData();
          const userCredentials = {
            email: userData.email,
            password: userData.password,
            role: userData.role,
          };
          // Modify state value before adding to formData
          const modifiedUserData = {
            ...userData,
          };

          // Add modified data to formData
          Object.keys(modifiedUserData).forEach((key) => {
            formData.append(key, modifiedUserData[key]);
          });

          const response = await axios.post("/users", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data) {
            try {
              await axios.post(
                "/mail",
                {
                  email: userCredentials.email,
                  password: userCredentials.password,
                  role: userCredentials.role,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );
            } catch (emailError) {
              console.error("Error sending credentials email:", emailError);
            }
          }

          this.userData = response;
        }
      } catch (err) {
        let errorMessage = "An error occurred";
        if (err.response) {
          errorMessage = err.response.data.message || errorMessage;
        } else if (err.request) {
          errorMessage = "No response from server";
        } else {
          errorMessage = "Error setting up the request";
        }
        this.error = errorMessage;
        return { success: false, message: errorMessage };
      } finally {
        this.isLoading = false;
      }
    },
    async editUser(UserData) {
      try {
        if (!UserData) {
          throw new Error("User data is required");
        } else {
          this.isLoading = true;
          const token = localStorage.getItem("token");
          const formData = new FormData();
          Object.keys(UserData).forEach((key) => {
            formData.append(key, UserData[key]);
          });
          const response = await axios.put(`/users/${UserData.uid}`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          this.userData = response.data;
        }
      } catch (error) {
        let errorMessage = "An error occurred";
        this.error = errorMessage;
        return { success: false, message: errorMessage };
      } finally {
        this.isLoading = false;
      }
    },

    async usersById(uid) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`/users/${uid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const users = response.data;

        this.users = users;

        return this.users;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchUsers() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const users = response.data;
        users.sort((a, b) => {
          return (formatDateFromTimestamp(b.createdAt) ?? "").localeCompare(
            formatDateFromTimestamp(a.createdAt) ?? ""
          );
        });
        this.users = users;

        return this.users;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCarrier() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/users/carriers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const carrier = response.data;
        this.carrier = carrier;
        return [this.carrier];
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },
    async fetchCompanies() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/companies", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const companies = response.data;
        this.companies = companies;
        return [this.companies];
      } catch (error) {
        console.error(error);
        this.handleError(error);
      }
    },
    async addCompany(companyData) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No se ha proporcionado un token de autenticación");
        }

        const response = await axios.post("/companies", companyData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        this.companies.push(response.data);
        return { success: true, data: response.data };
      } catch (error) {
        console.error("Error al agregar la compañía:", error);
        this.error = error.response?.data?.message || error.message;
        return { success: false, message: this.error };
      }
    },
    async getUsersLogs(uid) {
      try {
        this.isLoading = true;
        const token = localStorage.getItem("token");
        const response = await axios.get(`/users/${uid}/logs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const usersDataLogs = response.data;
        this.usersDataLogs = usersDataLogs;
        return this.usersDataLogs;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
