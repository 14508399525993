<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <div class="page-wrapper">
    <div class="content container-fluid container-sale-detail">
      <div class="d-flex align-items-center mb-4">
        <button type="button" class="btn me-2">
          <i @click="goBack" class="fa-solid fa-chevron-left fs-5"></i>
        </button>
        <div class="content-page-header mb-0">
          <h5>
            {{
              hasConfirmation
                ? t("sales.details.title1")
                : t("sales.details.title")
            }}
            {{ salesData.saleId }}
          </h5>
        </div>
      </div>
      <pre>{{ sales }}</pre>
      <div class="card p-3 pt-4">
        <div class="row">
          <div class="col-12 col-md-9 mb-2">
            <div class="rounded border p-4">
              <div
                class="d-flex justify-content-between align-items-center pt-2 pb-5"
              >
                <div style="width: 18%">
                  <img src="@/assets/img/logo.jpg" alt="Logo Details" />
                </div>
                <div>
                  <h5
                    class="mb-0 title"
                    style="font-size: 25px; color: #57015f"
                  >
                    {{ t("sales.details.title") }}
                  </h5>
                </div>
                <div class="text-end">
                  <div class="part-sale mt-2">
                    <p>
                      {{ t("sales.details.number") }}:
                      <strong>{{ salesData.saleId }}</strong>
                    </p>
                  </div>
                  <div class="part-sale mt-2">
                    <p>
                      {{ t("sales.details.date") }}
                      <strong>{{
                        formatDateCreate(salesData.createdAt)
                      }}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <section class="part-sale">
                <h3 style="color: #57015f">
                  {{ t("sales.details.primary.primary") }}
                </h3>
                <div class="row mt-3">
                  <div class="col-12 col-md-3" style="padding-left: 20px">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.firstName") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{
                          salesData.personalInfo?.firstName +
                            " " +
                            salesData.personalInfo?.middleName ?? "-"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.lastName") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.lastName ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.birthDate") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{
                          salesData.personalInfo &&
                          salesData.personalInfo.birthDate
                            ? formatDate(salesData.personalInfo.birthDate)
                            : "Date not available"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.gender") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.gender ?? "-" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-md-3" style="padding-left: 20px">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.socialSecurityNumber") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{
                          salesData.personalInfo?.socialSecurityNumber ?? "-"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.inmigrationStatus") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.inmigrationStatus ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.phone") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.phone ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.email") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.email ?? "-" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12 col-md-3" style="padding-left: 20px">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.address") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.address ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.zipCode") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.zipCode ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.state") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.state ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.primary.familySize") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.personalInfo?.familySize ?? "-" }}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section class="part-sale mt-5">
                <h3 style="color: #57015f">
                  {{ t("sales.details.plan.plan") }}
                </h3>
                <div class="row mt-3">
                  <div class="col-12 col-md-3" style="padding-left: 20px">
                    <div>
                      <p class="text-gray small-text textInter">
                        {{ t("sales.details.plan.selectedPlan") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.planInformation?.plan ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <!-- <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        Monthly payment
                      </p>
                      <p class="sale-value text-black small-text">
                        $ 1.000.000
                      </p>
                    </div>
                  </div> -->
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.plan.carrier") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{
                          (salesData.planInformation?.carrier ?? "-").replace(
                            /_/g,
                            " "
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section class="part-sale mt-5">
                <h3 style="color: #57015f">
                  {{ t("sales.details.payment.paymentMethod") }}
                </h3>
                <div class="row mt-3">
                  <div class="col-12 col-md-3" style="padding-left: 20px">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.payment.cardNumber") }}
                      </p>
                      <p
                        class="sale-value text-black small-text"
                        v-if="userRole === 'ADMIN' || userRole === 'SUPERVISOR'"
                      >
                        {{ salesData.payment?.cardNumber ?? "-" }}
                      </p>
                      <p
                        class="sale-value text-black small-text"
                        v-if="userRole !== 'ADMIN' && userRole !== 'SUPERVISOR'"
                      >
                        {{
                          salesData.payment?.cardNumber
                            ? maskCardNumber(salesData.payment.cardNumber)
                            : "-"
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.payment.fullcard") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.payment?.cardholderName ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.payment.cardDate") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.payment?.expirationDate ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">CVV</p>
                      <p
                        class="sale-value text-black small-text"
                        v-if="userRole === 'ADMIN' || userRole === 'SUPERVISOR'"
                      >
                        {{ salesData.payment?.cvv ?? "-" }}
                      </p>
                      <p
                        class="sale-value text-black small-text"
                        v-if="userRole !== 'ADMIN' && userRole !== 'SUPERVISOR'"
                      >
                        {{ salesData.payment?.cvv ? "***" : "-" }}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section class="part-sale mt-5">
                <h3 style="color: #57015f">
                  {{ t("sales.details.income.income") }}
                </h3>
                <div class="row mt-3">
                  <div class="col-12 col-md-3" style="padding-left: 20px">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.income.incomeType") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.income?.incomeType ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.income.phoneEmployer") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.income?.employersPhone ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.income.employer") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.income?.employerName ?? "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-12 col-md-3">
                    <div>
                      <p class="text-gray small-text">
                        {{ t("sales.details.income.annualIncome") }}
                      </p>
                      <p class="sale-value text-black small-text">
                        {{ salesData.income?.annualIncome ?? "-" }}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section class="part-sale mt-5">
                <h3 class="section-title">
                  {{ t("sales.details.supporting.document") }}
                </h3>
                <div class="table-responsive-container">
                  <div class="table-wrapper">
                    <table class="table table-striped mt-4">
                      <thead>
                        <tr>
                          <th>{{ t("sales.details.supporting.fileName") }}</th>
                          <th>{{ t("sales.details.supporting.fileType") }}</th>
                          <th>{{ t("sales.details.supporting.action") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(file, index) in uploadedFiles" :key="index">
                          <td>
                            {{ file.name.split(".").slice(0, -1).join(".") }}
                          </td>
                          <td>{{ file.type }}</td>
                          <td>
                            <span
                              @click="previewFile(file)"
                              class="view-link"
                              style="color: #57015f"
                            >
                              View
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              <section class="part-sale mt-5">
                <h3 style="color: #57015f">
                  {{ t("sales.details.members.members") }}
                </h3>
                <div class="row mt-3">
                  <div class="col-12 col-md-12">
                    <div class="accordion w-100" id="accordionExample">
                      <div
                        v-for="(member, index) in salesData.members"
                        :key="index"
                        class="accordion-item w-100"
                      >
                        <h2 class="accordion-header w-100">
                          <button
                            class="accordion-button text-black fw-bold"
                            type="button"
                            :data-bs-toggle="'collapse'"
                            :data-bs-target="`#collapse-${index}`"
                            aria-expanded="true"
                            :aria-controls="`collapse-${index}`"
                            style="
                              margin-bottom: 1rem;
                              background-color: #f5f5f2;
                              font-size: 0.875rem;
                            "
                          >
                            <span class="fs-6 fw-bold w-100 text-center">
                              Member
                              {{ index === 0 ? "Primary" : index + 1 }} -
                              <strong
                                >{{ member.firstName ?? "-" }}
                                {{ member.lastName ?? "-" }}</strong
                              >
                            </span>
                          </button>
                        </h2>

                        <div
                          :id="`collapse-${index}`"
                          class="accordion-collapse collapse w-100"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body p-0">
                            <div class="card m-0 w-100">
                              <div class="card-body">
                                <div class="row">
                                  <div class="col-12 col-md-3">
                                    <p class="text-gray small-text">
                                      {{ t("sales.details.members.firstName") }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{ member.firstName ?? "-" }}
                                    </p>
                                  </div>
                                  <div class="col-12 col-md-3">
                                    <p class="text-gray small-text">
                                      {{ t("sales.details.members.lastName") }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{ member.lastName ?? "-" }}
                                    </p>
                                  </div>
                                  <div class="col-12 col-md-3">
                                    <p class="text-gray small-text">
                                      {{ t("sales.details.members.birthDate") }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{
                                        member && member.birthDate
                                          ? formatDate(member.birthDate)
                                          : "Date not available"
                                      }}
                                    </p>
                                  </div>
                                </div>

                                <div class="row mt-3">
                                  <div class="col-12 col-md-3" v-if="index > 0">
                                    <p class="text-gray small-text">
                                      Relationship
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{ member.relationship ?? "-" }}
                                    </p>
                                  </div>
                                  <div class="col-12 col-md-3">
                                    <p class="text-gray small-text">
                                      {{ t("sales.details.members.gender") }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{ member.gender ?? "-" }}
                                    </p>
                                  </div>
                                  <div class="col-12 col-md-3">
                                    <p class="text-gray small-text">
                                      {{
                                        t(
                                          "sales.details.members.inmigrationStatus"
                                        )
                                      }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{ member.socialSecurityNumber ?? "-" }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row mt-3">
                                  <div class="col-12 col-md-3">
                                    <p class="text-gray small-text">
                                      {{
                                        t(
                                          "sales.details.members.inmigrationStatus"
                                        )
                                      }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{ member.inmigrationStatus ?? "-" }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row mt-3">
                                  <div class="col-6 col-md-3">
                                    <p class="text-gray small-text">
                                      {{
                                        t(
                                          "sales.details.members.relationship.otherRelationship"
                                        )
                                      }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      {{ member.otherRelationship ?? "-" }}
                                    </p>
                                  </div>
                                </div>
                                <div class="row mt-3">
                                  <div class="col-6 col-md-3">
                                    <p class="text-gray small-text">
                                      {{ t("sales.details.members.medical") }}
                                    </p>
                                    <p class="sale-value text-black small-text">
                                      ({{
                                        member.medicalCoverage == true
                                          ? "SI"
                                          : "NO"
                                      }})
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="part-sale mt-5">
                <h3 style="color: #57015f">
                  {{ t("sales.details.supporting.observations") }}
                </h3>
                <div class="row mt-3">
                  <div class="col-12 col-md-3" style="padding-left: 20px">
                    <p>{{ salesData.observations ?? "" }}</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div>
              <div
                v-if="hasConfirmation && salesData.status === 'DRAFT'"
                class="d-flex justify-content-between gap-4"
              >
                <button
                  @click="goBackNew"
                  type="button"
                  class="btn btn-outline-primary flex-fill rounded"
                >
                  {{ t("sales.details.buttons.back") }}
                </button>
                <button
                  @click="saleCompleted"
                  type="button"
                  class="btn btn-primary flex-fill rounded"
                >
                  {{ t("sales.details.buttons.finish") }}
                </button>
              </div>
              <div
                v-if="canApproveStatus(userRole, salesData.status)"
                class="d-flex justify-content-between gap-4"
              >
                <button
                  type="button"
                  class="btn btn-primary flex-fill rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#declineModal"
                >
                  {{ t("sales.details.buttons.declined") }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary flex-fill rounded"
                  data-bs-toggle="modal"
                  data-bs-target="#approveMosal"
                >
                  {{ t("sales.details.buttons.aprovated") }}
                </button>
              </div>

              <div
                v-if="userRole === 'SELLER' && salesData.status === 'REJECTED'"
                class="d-flex justify-content-between gap-4"
              >
                <button
                  type="button"
                  class="btn btn-primary flex-fill rounded"
                  @click="goBack"
                >
                  {{ t("sales.details.buttons.cancel") }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary flex-fill rounded"
                  @click="goBackNew"
                >
                  {{ t("sales.details.buttons.edit") }}
                </button>
                <button
                  @click="saleCorrected"
                  type="button"
                  class="btn btn-primary flex-fill rounded"
                >
                  {{ t("sales.details.buttons.finish") }}
                </button>
              </div>

              <!-- Details Section -->
              <div class="details-card rounded border p-3 mt-4">
                <h5 class="details-title mb-3" style="color: #57015f">
                  {{ t("sales.details.history.details") }}
                </h5>
                <div class="grid gap-3">
                  <div class="mb-2">
                    <div class="d-flex align-items-center">
                      <span class="text-muted small-text">{{
                        t("sales.details.history.status")
                      }}</span>
                      <p
                        :class="statusBadgeClass(salesData.status)"
                        style="margin-left: 50px"
                      >
                        {{ capitalize(salesData.status) ?? "-" }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="
                      !(
                        userRole === 'SELLER' && salesData.status === 'REJECTED'
                      )
                    "
                  >
                    <div class="mb-2 mt-4">
                      <div class="d-flex align-items-center">
                        <span class="text-muted small-text">{{
                          t("sales.details.history.seller")
                        }}</span>
                        <p
                          class="small-text"
                          style="margin-left: 40px; text-align: justify"
                        >
                          {{ salesData.sellerFullName ?? "-" }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2 mt-4">
                    <div class="d-flex align-items-center">
                      <span class="text-muted small-text">{{
                        t("sales.details.history.calidad")
                      }}</span>
                      <p
                        class="small-text"
                        style="margin-left: 20px; text-align: justify"
                      >
                        {{ salesData.assignedToCalidadName ?? "-" }}
                      </p>
                    </div>
                  </div>

                  <div class="mb-2 mt-4">
                    <div class="d-flex align-items-center">
                      <span class="text-muted small-text">{{
                        t("sales.details.history.processor")
                      }}</span>
                      <p
                        class="small-text"
                        style="margin-left: 20px; text-align: justify"
                      >
                        {{ salesData.assignedToName ?? "-" }}
                      </p>
                    </div>
                  </div>

                  <div v-if="salesData.status === 'REJECTED'">
                    <div class="mb-2 mt-4">
                      <div class="d-flex justify-content-between">
                        <p class="text-muted small-text">
                          {{ t("sales.details.history.reason") }}
                        </p>
                        <p
                          style="margin-left: 70px; font-size: 15px; width: 70%"
                        >
                          {{ salesData.comments }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Timeline Section -->
                <section style="margin-top: 50px">
                  <h5 class="timeline-title mb-3" style="color: #57015f">
                    {{ t("sales.details.history.timeLine.time") }}
                  </h5>
                  <div
                    style="max-height: 920px; overflow-y: auto; padding: 10px"
                  >
                    <a-timeline>
                      <a-timeline-item
                        v-for="log in salesDataLogs"
                        :key="log.logId"
                        :color="'#57015f'"
                        class="custom-timeline-item"
                      >
                        <div class="timeline-content gray-text">
                          <span v-html="formatLogMessage(log.message)"></span>
                          <div
                            v-if="
                              log.newValue && log.newValue.startsWith('http')
                            "
                          >
                            <div class="mt-4 d-flex align-items-center">
                              <span
                                @click="previewImage(log.newValue)"
                                class="view-link"
                                style="color: #57015f"
                                ><i class="fa-solid fa-image"></i>
                                Comprobante
                              </span>
                            </div>
                          </div>
                        </div>
                        <span
                          class="timeline-date"
                          style="color: #99a1a8; font-size: 11px"
                        >
                          {{ formatDateFromTimestamp(log.timestamp) }}
                        </span>
                      </a-timeline-item>
                    </a-timeline>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SalesModalBotton></SalesModalBotton>
</template>
<script setup>
import { maskCardNumber } from "@/utils/";
import { useSaleStore } from "@/store/sales";
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { format } from "date-fns";
import { Notyf } from "notyf";
import {
  capitalize,
  formatDateFromTimestamp,
  formatDateToISO,
  formatDate,
} from "@/utils";

import { t } from "@/utils/i18n";
import SalesModalBotton from "@/components/modal/sales-modal-botton.vue";
const saleStore = useSaleStore();
const salesData = ref({});
const salesDataLogs = ref({});
const route = useRoute();
const router = useRouter();
const userInfo = localStorage.getItem("user");
const userRole = JSON.parse(userInfo).role;

const notyf = new Notyf();
const uid = Object.keys(route.query)[0];

const isoading = ref(true);
const uploadedFiles = ref([]);

const hasProcessorAccess = (userRole, status) => {
  return userRole === "PROCESSOR" && ["ASSIGNED", "CORRECTED"].includes(status);
};

const hasQualityAccess = (userRole, status) => {
  return userRole === "CALIDAD" && ["TO_REVIEW", "CORRECTED"].includes(status);
};

const hasManagerialAccess = (userRole, status) => {
  return ["SUPERVISOR", "ADMIN"].includes(userRole) && status === "SUBMITTED";
};

const canApproveStatus = (userRole, status) => {
  return (
    hasProcessorAccess(userRole, status) ||
    hasQualityAccess(userRole, status) ||
    hasManagerialAccess(userRole, status)
  );
};

const formatDateCreate = (firebaseTimestamp) => {
  try {
    const milliseconds = firebaseTimestamp._seconds * 1000;
    const date = new Date(milliseconds);

    return format(date, "MMM dd, yyyy");
  } catch (error) {
    return "Invalid date";
  }
};

const goBackNew = () => {
  router.push(`/sales/new-sale/${uid}`);
  saleEdit();
};

const goBack = () => {
  router.go(-1);
};

const saleById = async () => {
  try {
    const sales = await saleStore.fetchSaleById(uid);
    salesData.value = sales;
  } catch (error) {
    notyf.error("Error loading users");
  } finally {
    isoading.value = false;
  }
};

const statusBadgeClass = (status) => {
  switch (status) {
    case "DRAFT":
      return "badge bg-warning-light text-warning";
    case "ASSIGNED":
      return "badge bg-info-light text-info";
    case "CORRECTED":
      return "badge bg-light text-secondary";
    case "REJECTED":
      return "badge bg-warnin-light text-warnin";
    case "SUBMITTED":
      return "badge bg-success-light text-success";
    case "TO_REVIEW":
      return "badge gb-purple-light text-purple";
    default:
      return "badge";
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const formatLogMessage = (message) => {
  const states = [
    "justification",
    "declined",
    "accepted",
    "assigned",
    "corrected",
    "created",
    "reasigned",
  ];

  states.forEach((state) => {
    const regex = new RegExp(state, "gi");
    message = message.replace(regex, capitalizeFirstLetter(state));
  });

  const boldName = (name) => `<strong>${name}</strong>`;

  const handleNameAtStart = (msg) => {
    const parts = msg.split(" ");
    if (parts.length >= 2) {
      const possibleName = parts.slice(0, 2).join(" ");
      const restOfMessage = parts.slice(2).join(" ");

      if (/^[A-Z][a-z]+ [A-Z][a-z]+/.test(possibleName)) {
        return `${boldName(
          possibleName
        )} <span style="color: gray;">${restOfMessage}</span>`;
      }
    }
    return null;
  };

  if (message.toLowerCase().includes("added files")) {
    const parts = message.split(/"added files/i);
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">${t(
      "sales.details.history.timeLine.add"
    )}</span>`;
  }

  if (message.toLowerCase().includes("declined the sale")) {
    const parts = message.split(/declined the sale/i);
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">${t(
      "sales.details.history.timeLine.declined"
    )} ${boldName(parts[1])}</span>`;
  }

  if (message.toLowerCase().includes("assigned to")) {
    const parts = message.split(/assigned to/i);
    const name = parts[1].trim();
    return `<span style="color: gray;">${parts[0]} ${t(
      "sales.details.history.timeLine.assigned"
    )} </span>${boldName(name)}`;
  }

  if (message.includes("reAssigned sale")) {
    const parts = message.split(/reAssigned sale/i);
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">${t(
      "sales.details.history.timeLine.reasigned"
    )} ${boldName(parts[1])}</span>`;
  }

  if (message.includes("made a Justification")) {
    const parts = message.split("made a Justification");
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">${t(
      "sales.details.history.timeLine.comment"
    )} ${parts[1]}</span>`;
  }

  if (message.toLowerCase().includes("corrected the sale")) {
    const parts = message.split(/corrected the sale/i);
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">${t(
      "sales.details.history.timeLine.corrected"
    )}</span>`;
  }

  if (message.toLowerCase().includes("created sale")) {
    const parts = message.split(/created sale/i);
    const name = parts[0].trim();
    return `${boldName(name)} <span style="color: gray;">${t(
      "sales.details.history.timeLine.created"
    )} ${parts[1].trim()}</span>`;
  }

  const nameAtStartResult = handleNameAtStart(message);
  if (nameAtStartResult) {
    return nameAtStartResult;
  }

  return `<span style="color: gray;">${message}</span>`;
};

const previewFile = (file) => {
  // Normalizar la extensión del archivo eliminando el punto si existe
  const fileType = file.type.replace(".", "").toLowerCase();

  if (fileType === "pdf") {
    window.open(file.url, "_blank");
  } else if (fileType === "mp3" || fileType === "mp4") {
    const link = document.createElement("a");
    window.open(file.url, "_blank");
    link.download = file.name || `download.${fileType}`;
    link.click();
  } else if (["jpg", "jpeg", "png"].includes(fileType)) {
    const img = new Image();
    img.src = file.url;
    const w = window.open("");
    w.document.write(img.outerHTML);
  } else {
    console.log("Preview not available for this file type.");
  }
};

const hasConfirmation = computed(() =>
  route.query.hasOwnProperty("confirmation")
);

const previewImage = (imageUrl) => {
  const modal = document.createElement("div");
  modal.style.position = "fixed";
  modal.style.top = "50%";
  modal.style.left = "50%";
  modal.style.transform = "translate(-50%, -50%)";
  modal.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
  modal.style.padding = "20px";
  modal.style.borderRadius = "10px";
  modal.style.zIndex = "1000";
  modal.style.display = "block";
  document.body.appendChild(modal);

  // Add a close button to the modal
  const closeButton = document.createElement("button");
  closeButton.textContent = "X";
  closeButton.style.position = "absolute";
  closeButton.style.top = "10px";
  closeButton.style.right = "10px";
  closeButton.style.backgroundColor = "red"; // Changed background color to red
  closeButton.style.border = "none";
  closeButton.style.color = "white";
  closeButton.style.cursor = "pointer";
  closeButton.onclick = () => {
    document.body.removeChild(modal);
  };
  modal.appendChild(closeButton);

  // Add the image to the modal
  const img = document.createElement("img");
  img.src = imageUrl;
  img.style.width = "100%";
  img.style.height = "auto";
  modal.appendChild(img);
};

const saleCompleted = async () => {
  try {
    await saleStore.updateSaleCompleted(6, uid);
    notyf.success("Sale completed successfully");
    router.push(`/sales/sale-list`);
  } catch (error) {
    message.error("Failed to load existing files.");
  }
};
const saleCorrected = async () => {
  try {
    await saleStore.updateCorrected(uid);
    notyf.success("Sale Corrected successfully");
    router.push(`/sales/sale-list`);
  } catch (error) {
    message.error("Failed to load existing files.");
  }
};

const saleEdit = async () => {
  try {
    await saleStore.updateSaleCompleted(5, uid);
  } catch (error) {
    message.error("Failed to load existing files.");
  }
};

const loadExistingFiles = async () => {
  try {
    const files = await saleStore.getSaleAttachments(uid);
    uploadedFiles.value = files.map((file) => ({
      name: file.name,
      type: file.type,
      date: new Date(file.created_at)
        .toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        })
        .toUpperCase(),
      url: file.url,
      id: file.id,
    }));
  } catch (error) {
    message.error("Failed to load existing files.");
  }
};

const getLogs = async () => {
  try {
    const salesLogs = await saleStore.getSaleLogs(uid);
    salesDataLogs.value = salesLogs;
  } catch (error) {
    message.error("Failed to load existing Logs.");
  }
};

onMounted(saleById);
onMounted(() => {
  loadExistingFiles();
  getLogs();
});
</script>

<style scoped>
.texInter {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.h3 .title {
  color: #caa6e7;
}

.small-text {
  font-size: 0.85rem;
}
.text-gray {
  color: #99a1a8;
}
.text-black {
  color: #000;
}

h5.mb-3 {
  margin-bottom: 3rem;
}

.timeline-date .tittle {
  color: #c9dae9;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
.details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5rem;
}

.sale-field {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.detail-info {
  color: #000;
  font-size: 0.75rem;
}

.title {
  margin-bottom: 0.5rem;
}
.badge {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #fff;
}

.bg-info-light {
  background-color: #d1ecf1;
}

.text-info {
  color: #0c5460;
}

.span-primary {
  background-color: #caa6e7;
  color: #57015f;
}

.bg-warnin-light {
  background-color: #ffede7;
  color: #fe0000;
}

.bg-warning-light {
  background-color: #fff3cd;
}

.text-warning {
  color: #856404;
}

.part-sale {
  width: 100%;
  position: relative;
}

.section-title {
  color: #57015f;
  margin-bottom: 1rem;
}

.table-responsive-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  min-width: 500px;
  margin-bottom: 0;
  background-color: white;
}

.table thead tr {
  background-color: white;
}

.table th {
  color: #57015f;
  padding: 1rem;
  white-space: nowrap;
  border-bottom: 2px solid #ffffff;
}

.table tbody {
  background-color: white;
}

.table td {
  padding: 1rem;
  vertical-align: middle;
  border-top: 1px solid #ffffff;
}

.view-link {
  cursor: pointer;
  text-decoration: underline;
  color: #007bff;
}

@media screen and (max-width: 768px) {
  .table-wrapper {
    margin: 0 -15px;
    padding: 0 15px;
    width: calc(100% + 30px);
  }
}

.expand-button {
  color: #57015f;
  font-weight: 500;
}

.expand-button:hover {
  color: #760280;
}

.timeline-content {
  margin-bottom: 4px;
}

:deep(.ant-timeline-item-head) {
  background-color: #57015f !important;
  border-color: #57015f !important;
}

:deep(.ant-timeline-item-head-custom) {
  background: none !important;
}
</style>
