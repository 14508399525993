export default {
  es: {
    search: "Buscar aquí",
    notifications: "Notificaciones",
    viewAll: "Ver todo",
    markAllAsRead: "Marcar todo como leído",
    clearAll: "Borrar todo",
    profile: "Perfil",
    settings: "Configuración",
    logout: "Cerrar sesión",
    chngPassword: "Cambiar Clave",
    //DASBOARD
    dashboard: {
      hi: "Hola",
      text: "Es un placer tenerte de nuevo, aquí tienes tu información a mano",
      title1: "Recuento Mensual de Ventas",
      title2: "Resumen de Ventas por Empresa",
      text1: "Total Ventas",
      text2: "Resumen de Ventas por Estado",
      text3: "Planes de Servicio",
      cards: {
        all: "All",
        assigned: "Asignada",
        rejected: "Rechazada",
        corrected: "Corregida",
        submitted: "Sometida",
        draft: "Borrrador",
        to_review: "Por revisar",
      },
      table: {
        name: "Nombre",
        socialSecurityNumber: "Número de la Seguridad Social",
        status: "Estado",
        birthDate: "Fecha de Nacimiento",
        carrier: "Plan",
        company: "Compañia",
        creation: "Fecha Creación",
        email: "Correo",
        phone: "Teléfono",
        state: "Estado",
        seller: "Vendedor",
        processor: "Procesador",
        calidad: "Calidad",
      },
    },
    sales: {
      newSale: {
        title: "Número de ventas registradas por mes",
        btnNewSale: "Nueva Venta",
      },
      details: {
        title: "Información de Venta",
        title1: "Confirmación de la información de venta",
        number: "Número de Venta",
        date: "Fecha de Venta",
        primary: {
          primary: "Principal",
          firstName: "Nombres",
          middlename: "Segundo Nombre",
          lastName: "Apellido",
          birthDate: "Fecha de Nacimiento",
          gender: "Genero",
          socialSecurityNumber: "Número de Seguridad Social",
          inmigrationStatus: "Estado de Migración",
          phone: "Telefono",
          email: "Correo Electrónico",
          address: "Dirección",
          zipCode: "Codigo Postal",
          state: "Estado",
          familySize: "cantidad Familia",
          married: "¿Esta casado?",
          services: "¿Solicitar servicios de contabiliad?",
          household: "Ingresos familiares",
        },
        plan: {
          plan: "Plan",
          selectedPlan: "Seleccione plan",
          carrier: "Transportador",
        },
        payment: {
          paymentMethod: "Forma de Pago",
          cardNumber: "Número de Tarjeta",
          fullcard: "Nombre completo de Tarjeta",
          cardDate: "Fecha caducidad de Tarjeta",
        },
        income: {
          income: "Ingresos",
          incomeType: "Tipo de Ingreso",
          phoneEmployer: "Teléfono del Empleado",
          employer: "Profesión",
          annualIncome: "Ingresos Anuales",
          employed: "Empleado",
          selfemployed: "Independiente",
          unemployed: "Desempleado",
        },
        supporting: {
          document: "Documento de Apoyo",
          fileName: "Nombre de Fichero",
          fileType: "Tipo de Fichero",
          action: "Accion",
          date: "Fecha de Creación",
          txt: "Adjunte los justificantes necesarios para continuar con la venta: Consentimiento informado (PDF), archivo de audio de la llamada (MP3/MP4).",
          observations: "Observaciones / Link de Plan",
        },
        members: {
          members: "Miembros",
          primary: "Principal",
          memberOne: "Miembro",
          membersPrimary: "Miembro Principales",
          firstName: "Nombre",
          lastName: "Apellido",
          birthDate: "Fecha de Nacimiento",
          gender: "Genero",
          socialSecurityNumber: "Número de Seguridad Social",
          inmigrationStatus: "Estado de Migración",
          medical: "¿Aplica cobertura Medica?",
          relationship: {
            relationship: "Parentesco",
            dad: "Padre",
            mom: "Madre",
            son: "Hijo",
            daughter: "Hija",
            grandpa: "Abuelo",
            grandma: "Abuela",
            other: "Otro",
            otherRelationship: "Otro Parentesco",
          },
        },
        history: {
          details: "Detalles",
          status: "Estado",
          seller: "Vendedor",
          processor: "Procesador",
          calidad: "Calidad",
          reason: "Comentario",
          timeLine: {
            time: "Linea de Tiempo",
            accept: "Acepta la Venta",
            corrected: "ha Corregido la Venta",
            comment: "hizo un Comentario",
            declined: "Declino la Venta",
            reasigned: "Reasigno la Venta",
            sale: "Venta",
            assigned: "Assginado a ",
            add: "Agrego Archivos",
            created: "registro Nueva Venta",
          },
        },
        buttons: {
          cancel: "Cancelar",
          back: "Atras",
          declined: "Declinar",
          aprovated: "Aprobar",
          edit: "Editar",
          finish: "Finalizar",
          continue: "Continuar",
          next: "Siguiente",
        },
      },
      users: {
        users: "Usuarios",
        add: "Nuevo Usuario",
        addCompany: "Nueva Compañia",
        nameCompany: "Nombre Compañia",
        createCompany: "Crear Compañia",
        name: "Nombre de Usuario",
        phone: "Telefono",
        role: "Rol",
        company: "Compañia",
        carrier: "Transportador",
        created: "Creado",
        status: "Estado",
        action: "Acción",
        newUsers: {
          edit: "Editar",
          delete: "Eliminar",
          profile: "Foto de Perfil",
          newPhoto: "Nueva Foto",
          firstName: "Nombre",
          middlename: "Segundo Nombre",
          lastName: "Apellido",
          email: "Correo",
          phone: "Telefono",
          role: "Rol",
          company: "Compañia",
          selectCompany: "Seleccione Compañia",
          carrier: "Transportador",
          selectCarrier: "Seleccione Transportador",
          password: "Contraseña",
          confirmPassword: "Confirmar Contraseña",
          state: "Estado",
          newpassword: "Nueva Contraseña",
        },
      },
      modal: {
        declined: {
          declined: "Declinar Venta",
          comment: "Comentario",
          img: "Cargar Imagen",
          txt: "Escriba el motivo de rechazo",
        },
        reasignedProcessor: {
          btnreassign: "Reasignar Procesador",
          reassign: "Reasignar Ventas",
          txtreassign:
            "Seleccione el procesador al que desea reasignar las ventas seleccionadas.",
          pch: "Seleccionar Procesador",
          btnCancel: "Cancelar",
          btnContinue: "Continuar",
        },
        changePassword: {
          txtTitle: "Cambiar Contraseña",
          txtSubtitle: "Digite su nueva clave y confirmela",
        },
      },
    },
  },
  en: {
    search: "Search here",
    notifications: "Notifications",
    viewAll: "View all",
    markAllAsRead: "Mark all as read",
    clearAll: "Clear All",
    profile: "Profile",
    settings: "Settings",
    logout: "Log Out",
    chngPassword: "Change password",
    dashboard: {
      hi: "Hello",
      text: "It's a pleasure to have you again, here you have your information at hand",
      title1: "Monthly Sales Count",
      title2: "Sales Summary by Company",
      text1: "Total Sales",
      text2: "Sales Summary by Status",
      text3: "Service plans",
      cards: {
        all: "All",
        assigned: "Assigned",
        rejected: "Rejected",
        corrected: "Corrected",
        submitted: "Submitted",
        draft: "Draft",
        to_review: "To Review",
      },
      table: {
        name: "Name",
        socialSecurityNumber: "Social Security Number",
        status: "Status",
        birthDate: "Birth Date",
        carrier: "Carrier",
        company: "Company",
        creation: "Creation date",
        email: "Email",
        phone: "Phone number",
        state: "State",
        seller: "Seller",
        processor: "Processor",
        calidad: "Calidad",
      },
    },
    sales: {
      newSale: {
        title: "Number of sales recorded per month",
        btnNewSale: "New Sale",
      },
      details: {
        title: "Sale information",
        title1: "Confirmation of sale information",
        number: "Sale number",
        date: "Sale date",
        primary: {
          primary: "Primary",
          firstName: "First Name",
          middlename: "Middle Name",
          lastName: "Last Name",
          birthDate: "Date of birth",
          gender: "Gender",
          socialSecurityNumber: "Social Security number",
          inmigrationStatus: "Immigration status",
          phone: "Phone number",
          email: "Email address",
          address: "Address",
          zipCode: "Postal code",
          state: "State",
          familySize: "Family size",
          married: "Are you married?",
          services: "Request accounting services?",
          household: "Household income",
        },
        plan: {
          plan: "Plan",
          selectedPlan: "Selected plan",
          carrier: "Carrier",
        },
        payment: {
          paymentMethod: "Payment method",
          cardNumber: "Card Number",
          fullcard: "Full name of the card",
          cardDate: "Card expiration date",
        },
        income: {
          income: "Income",
          incomeType: "Type of income",
          phoneEmployer: "Employer's phone number",
          employer: "Profession",
          annualIncome: "Annual income",
          employed: "Employed",
          selfemployed: "Self-employed",
          unemployed: "Unemployed",
        },
        supporting: {
          document: "Supporting document",
          fileName: "File name",
          fileType: "File Type",
          action: "Action",
          date: "Date of creation",
          txt: "Please attach the required supporting documents to continue with the sale: Informed consent (PDF), audio file of the call (MP3/MP4).",
          observations: "Observations / Plan Link ",
        },
        members: {
          members: "Members",
          primary: "Primary",
          memberOne: "Members",
          membersPrimary: "Member Primary",
          firstName: "first Name",
          lastName: "last Name",
          birthDate: "birth Date",
          gender: "Gender",
          socialSecurityNumber: "Social Security Number",
          inmigrationStatus: "Immigration Status",
          medical: "Apply for medical coverage?",
          relationship: {
            relationship: "Relationship",
            dad: "Dad",
            mom: "Mom",
            son: "Son",
            daughter: "Daughter",
            grandpa: "Grandpa",
            grandma: "Grandma",
            other: "Other",
            otherRelationship: "Other Relationship",
          },
        },
        history: {
          details: "Details",
          status: "Status",
          seller: "Seller",
          processor: "Processor",
          calidad: "Calidad",
          reason: "Reason",
          timeLine: {
            time: "Time line",
            accept: "Accepted the sale",
            corrected: "Corrected the sale",
            comment: "made a Justification",
            declined: "Declined the sale",
            reasigned: "Reassigned sale",
            sale: "Sale",
            assigned: "Assigned to",
            add: "Added Files",
            created: "Created sale",
          },
        },
        buttons: {
          back: "Back",
          declined: "Decline",
          aprovated: "Aprove",
          cancel: "Cancel",
          edit: "Edit",
          finish: "Finish",
          next: "Next",
          continue: "Continue",
        },
      },
      users: {
        users: "Users",
        add: "Add User",
        addCompany: "Add Company",
        nameCompany: "Company Name",
        createCompany: "Create Company",
        name: "User Name",
        phone: "Phone Number",
        role: "Role",
        company: "Company",
        carrier: "Carrier",
        created: "Creaeted On",
        status: "Status",
        action: "Action",
        newUsers: {
          edit: "Edit",
          delete: "Delete",
          profile: "Profile Picture",
          newPhoto: "Upload a New Photo",
          firstName: "First Name",
          middlename: "Middle Name",
          lastName: "Last Name",
          email: "Email",
          phone: "Phone",
          role: "Role",
          company: "Company",
          selectCompany: "Select Company",
          carrier: "Carrier",
          selectCarrier: "Select Carrier",
          password: "Password",
          confirmPassword: "Confirm Password",
          state: "State",
          newpassword: "New Password",
        },
      },
      modal: {
        declined: {
          declined: "Decline Sale",
          comment: "Reason",
          img: "Upload Image",
          txt: "Write the reason for rejection",
        },
        reasignedProcessor: {
          btnreassign: "Reassign processor",
          reassign: "Reallocate Sales",
          txtreassign:
            "Select the processor to which you want to reassign the selected sales.",
          pch: "Select processor",
          btnCancel: "Cancel",
          btnContinue: "Continue",
        },
        changePassword: {
          txtTitle: "Change Password",
          txtSubtitle: "Enter your new password and confirm it",
        },
      },
    },
  },
};
