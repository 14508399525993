<script setup>
import dayjs from "dayjs";
import { Notyf } from "notyf";
import { ref, onMounted, computed } from "vue";
import { useUserStore } from "@/store/user";
import { useSaleStore } from "@/store/sales";
import {
  formatDate,
  formatDateFromTimestamp,
  getFullName,
  extractName,
} from "@/utils";
import * as XLSX from "xlsx";
import { t } from "@/utils/i18n";

const notyf = new Notyf();
const userStore = useUserStore();

const activeKey = ref("1");
const title = computed(() => t("sales.newSale.title"));
const text = computed(() => t("sales.newSale.btnNewSale"));
const path = "/sales/new-sale";
const isReassignEnabled = ref(false);

const saleStore = useSaleStore();
const sales = ref([]);
const salesByStatus = ref({});
const userProcessor = ref({});
const errorMessage = ref("");
const salesFilter = ref("");
const saleIds = ref([]);
const selectedProcessorUid = ref("");
const isSearchValue = ref(false);
const userInfo = localStorage.getItem("user");
const userRole = JSON.parse(userInfo).role;

const loadProcessors = async () => {
  try {
    const userData = await userStore.fetchUsers();
    userProcessor.value = userData.filter(
      (user) =>
        user.role === "PROCESSOR" && ["ACTIVE", "ASSET"].includes(user.state)
    );
  } catch (error) {
    console.log("Error al cargar procesadores", error);
  }
};

const getFilteredSales = async (dateRange, filters) => {
  try {
    const [salesData, salesByStatusData] =
      await saleStore.fetchSalesByDateRangeAndStatus(dateRange, filters);

    sales.value = salesData;
    salesByStatus.value = salesByStatusData;
    errorMessage.value = "";
  } catch (error) {
    sales.value = [];
    salesByStatus.value = [];
    errorMessage.value = saleStore.error.message;
    notyf.error(errorMessage.value);
  }
};

const getCurrentStatusName = () => {
  const statusMap = {
    2: t("dashboard.cards.assigned"),
    3: t("dashboard.cards.rejected"),
    4: t("dashboard.cards.corrected"),
  };
  return statusMap[activeKey.value] || "Todos";
};

const getDataToExport = () => {
  const dataMap = {
    2: salesByStatus.value.ASSIGNED,
    3: salesByStatus.value.REJECTED,
    4: salesByStatus.value.SUBMITTED,
  };
  return dataMap[activeKey.value] || sales.value || [];
};

const exportToExcel = () => {
  const dataToExport = getDataToExport();

  const exportData = dataToExport.map((record) => ({
    ID: record.saleId,
    Nombre: record.personalInfo
      ? getFullName(record.personalInfo.firstName, record.personalInfo.lastName)
      : "",
    "Número de la Seguridad Social":
      record.personalInfo.socialSecurityNumber || "",
    "Fecha de Nacimiento": record.personalInfo?.birthDate
      ? formatDate(record.personalInfo.birthDate)
      : "",
    Carrier: record.planInformation?.carrier || "",
    "Fecha de Creación": record.createdAt
      ? formatDateFromTimestamp(record.createdAt)
      : "",
    "Correo Electrónico": record.personalInfo?.email || "",
    Teléfono: record.personalInfo?.phone || "",
    State: record.personalInfo?.state || "",
    Compañia: record.sellerCompanyName || "",
    Vendedor: record.sellerFullName || "",
    Calidad: record.assignedToCalidadName || "",
    Procesador: record.assignedToName || "",
    Estado: record.status || "",
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Datos de Ventas");

  const statusName = getCurrentStatusName();
  const fileName = `Reporte_Ventas_${statusName.toLowerCase()}_${
    new Date().toISOString().split("T")[0]
  }.xlsx`;

  XLSX.writeFile(workbook, fileName);
};

const currentDate = dayjs().format("YYYY-MM-DD");
const lastMonthDate = dayjs().subtract(1, "month").format("YYYY-MM-DD");
const dateRange = ref({ startDate: lastMonthDate, endDate: currentDate });

function updateDateRange(dates) {
  dateRange.value = dates;
  getFilteredSales(dateRange.value, salesFilter.value);
}

function filterSales({ carrier, processor, seller }) {
  let strFilter = "";

  if (carrier) strFilter += `planInformation.carrier=${carrier}`;
  if (processor) strFilter += `&assignedTo=${processor}`;
  if (seller) strFilter += `&sellerId=${seller}`;

  salesFilter.value = strFilter;
  getFilteredSales(dateRange.value, strFilter);
}

function searchSales({ value, attribute }) {
  if (isSearchValue.value & !value & !attribute) {
    getFilteredSales(dateRange.value, "");
    isSearchValue.value = false;
  } else {
    let strFilter = "";

    switch (attribute) {
      case "saleId":
        strFilter += `saleId=${value}`;
        break;
      case "FullName": {
        const { firstName, lastName } = extractName(value);
        strFilter += `personalInfo.firstName=${firstName}&personalInfo.lastName=${lastName}`;
        break;
      }
      case "firstName":
        strFilter += `personalInfo.firstName=${value}`;
        break;
      case "birthDate":
        strFilter += `personalInfo.birthDate=${dayjs(value).format(
          "YYYY-MM-DD"
        )}`;
        break;
      case "email":
        strFilter += `personalInfo.email=${value}`;
        break;
      case "phone":
        strFilter += `personalInfo.phone=${value}`;
        break;
      default:
        console.log("predeterminado: ", value);
    }

    isSearchValue.value = true;
    getFilteredSales(dateRange.value, strFilter);
  }
}

function handleSalesReassign(uidList) {
  isReassignEnabled.value = uidList.length > 0;
  saleIds.value = uidList;
}

const reasignedSales = async (saleIds, selectedProcessorUid) => {
  try {
    saleStore.reasignedSales(saleIds, selectedProcessorUid);
    notyf.success("Reasignación completada exitosamente.");

    const btnClose = document.getElementById("btnModalClose");

    saleStore.resetUidList();
    saleIds.value = [];
    isReassignEnabled.value = false;

    setTimeout(() => {
      btnClose.click();
      getFilteredSales(dateRange.value, "");
    }, 1000);
  } catch (error) {
    notyf.error(
      "Error al completar la reasignación. Por favor intente nuevamente."
    );
  }
};

onMounted(() => {
  getFilteredSales(dateRange.value, "");
  loadProcessors();
});
</script>

<template>
  <layout-header
    @dates-changed="updateDateRange"
    @searchSales="searchSales"
  ></layout-header>
  <layout-sidebar></layout-sidebar>

  <div class="page-wrapper">
    <div class="content container-fluid content-sales">
      <recurringheader
        :title="title"
        :path="path"
        :text="text"
        :error="errorMessage"
        @filter-sales="filterSales"
      />
      <searchfilter />
      <sales-card :dateRange="dateRange" />

      <div class="filter-container">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" :tab="t('dashboard.cards.all')">
            <data-table
              :data="sales || []"
              :activeKey="activeKey"
              @onSalesReassignRequested="handleSalesReassign"
            />
          </a-tab-pane>
          <a-tab-pane key="2" :tab="t('dashboard.cards.assigned')" force-render>
            <data-table
              :data="salesByStatus.ASSIGNED || []"
              @onSalesReassignRequested="handleSalesReassign"
            />
          </a-tab-pane>
          <a-tab-pane key="3" :tab="t('dashboard.cards.rejected')" force-render>
            <data-table
              :data="salesByStatus.REJECTED || []"
              @onSalesReassignRequested="handleSalesReassign"
            />
          </a-tab-pane>
          <a-tab-pane
            key="4"
            :tab="t('dashboard.cards.submitted')"
            force-render
          >
            <data-table :data="salesByStatus.SUBMITTED || []" />
          </a-tab-pane>
          <template #rightExtra>
            <div
              class="input-block mb-4 d-flex align-items-center justify-content-between"
            >
              <button
                type="button"
                class="btn btn-primary me-3 rounded-2"
                v-if="isReassignEnabled"
                data-bs-toggle="modal"
                data-bs-target="#reassignDocumentModal"
              >
                <i class="feather feather-repeat"></i>
                {{ t("sales.modal.reasignedProcessor.btnreassign") }}
              </button>
              <button
                v-if="
                  userRole == 'ADMIN' ||
                  userRole == 'SUPERVISOR' ||
                  userRole == 'SELLER'
                "
                type="button"
                @click="exportToExcel"
                class="btn btn-outline-primary2"
              >
                <i class="fas fa-download"></i> Exportar Excel
              </button>
            </div>
          </template>
        </a-tabs>
      </div>

      <div
        class="modal fade"
        id="reassignDocumentModal"
        tabindex="-1"
        aria-labelledby="reassignDocumentModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="reassignDocumentModalLabel">
                {{ t("sales.modal.reasignedProcessor.reassign") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-left p-4">
              <p class="mb-0">
                {{ t("sales.modal.reasignedProcessor.txtreassign") }}
              </p>

              <div class="carrier-select-container me-3 mt-3 w-100">
                <select
                  ref="processorSelect"
                  class="form-control"
                  v-model="selectedProcessorUid"
                >
                  <option value="">
                    {{ t("sales.modal.reasignedProcessor.pch") }}
                  </option>
                  <option
                    v-for="processor in userProcessor"
                    :key="processor.uid"
                    :value="processor.uid"
                  >
                    {{ processor.firstName }} {{ processor.lastName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer mt-4 p-4 pt-0">
              <button
                type="button"
                class="btn btn-outline-primary2 me-3"
                data-bs-dismiss="modal"
                id="btnModalClose"
              >
                {{ t("sales.modal.reasignedProcessor.btnCancel") }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="reasignedSales(saleIds, selectedProcessorUid)"
              >
                {{ t("sales.modal.reasignedProcessor.btnContinue") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
